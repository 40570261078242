import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    HARDWARE: "Hardware",
    SOFTWARE: "Software",
  };

  const projectsData = [
    {
      title: "Pecaps",
      projectInfo:
        "Created 3D printed artisan keycaps. The keycaps were hand painted and hand packaged. They were sold globally to a small community of keyboard enthusiasts. With many loving reviews of the product.",
      technologies: "3D Printing | 3D Modelling | Digital Art",
      industry: "Mechanical Keyboards",
      date: "Mar, 2022",
      thumbImage: "images/projects/project_1.0.jpg",
      sliderImages: [
        "images/projects/project_1.1.jpg",
        "images/projects/project_1.2.jpg",
        "images/projects/project_1.3.jpg",
        "images/projects/project_1.4.jpg",
        "images/projects/project_1.5.jpg",
        "images/projects/project_1.6.jpg",
      ],
      categories: [filters.HARDWARE],
    },
    {
      title: "Racing Drones",
      projectInfo:
        "Built racing drones that could fly as fast as 100Mph! Even getting a small crowd of people when they would be whizzing around. The drones had a range of over a mile.",
      technologies: "Microcontroller | GPS Tracking | 3D Printing",
      industry: "Drone",
      date: "Jan, 2021",
      thumbImage: "images/projects/project_2.0.jpg",
      sliderImages: [
        "images/projects/project_2.1.jpg",
        "images/projects/project_2.2.jpg",
        "images/projects/project_2.3.jpg",
        "images/projects/project_2.4.jpg",
        "images/projects/project_2.5.jpg",
      ],
      categories: [filters.HARDWARE],
    },
    {
      title: "Nixie Tube Clock",
      projectInfo:
        "Designed and made the circuitry to enable the clock to function. The clock was programmed using Arduino Uno. With the casing being 3D printed.",
      technologies: "3D Printing | 3D Modelling | Microcontroller",
      industry: "Electronics",
      date: "July, 2019",
      thumbImage: "images/projects/project_3.0.jpg",
      categories: [filters.HARDWARE],
    },
    {
      title: "3D Printing",
      projectInfo:
        "3D printed many projects. Ranging from a simple tool to hold a brush, to a full-scale cosplay helmet. The projects were often finished by sanding down and painting with an airbrush",
      technologies: "3D Printing | Airbrush | Slicing",
      industry: "3D Printing",
      date: "July 16, 2019",
      thumbImage: "images/projects/project_4.0.jpg",
      sliderImages: [
        "images/projects/project_4.1.jpg",
        "images/projects/project_4.2.jpg",
        "images/projects/project_4.3.jpg",
        "images/projects/project_4.4.jpg",
      ],
      categories: [filters.HARDWARE],
    },
    {
      title: "Color Changing Ball",
      projectInfo:
        "3D printed cosplay ball with LED ring lights and wireless charging capabilities. The color of the ball can be controlled with a wireless device that can detect color. Changing the color of the ball to the detected color.",
      technologies: "3D Printing | ESP32 | Wirless charging",
      industry: "Cosplay",
      date: "May, 2020",
      thumbImage: "images/projects/project_5.0.jpg",
      sliderImages: [
        "images/projects/project_5.1.jpg",
        "images/projects/project_5.2.jpg",
        "images/projects/project_5.3.jpg",
        "images/projects/project_5.4.jpg",
      ],
      categories: [filters.HARDWARE],
    },
    {
      title: "Two Wheel Balancing Robot",
      projectInfo:
        "The robot was built as a final year project. It was modelled in MATLAB with all the physics calculated. With the model being used to verify the project before building a small and full-scale version.",
      technologies: "Microcontroller | MATLAB | 3D Modelling",
      industry: "Robotics",
      date: "May, 2022",
      thumbImage: "images/projects/project_6.0.jpg",
      sliderImages: [
        "images/projects/project_6.1.jpg",
        "images/projects/project_6.2.jpg",
        "images/projects/project_6.3.jpg",
        "images/projects/project_6.4.jpg",
      ],
      categories: [filters.HARDWARE],
    },
    {
      title: "Book Pages To Images",
      projectInfo:
        "The project was made to create images out of the pages of the book that were presently being read. Using Chat GPT to summarise the page and Midjourney to create images of the page.",
      technologies: "Chat GPT | Midjourney | Python",
      industry: "AI",
      date: "July, 2023",
      thumbImage: "images/projects/project_7.0.jpg",
      sliderImages: [
        "images/projects/project_7.1.jpg",
        "images/projects/project_7.2.jpg",
      ],
      categories: [filters.SOFTWARE],
    },
    {
      title: "eBay Bidding Bot",
      projectInfo:
        "Bot created using selenium with the GUI created using Tkinter. The bot runs locally on the user's computer and waits the pre-established amount of time before bidding on the item.",
      technologies: "Python | Tkinter | Selenium",
      industry: "E-commerce",
      date: "Oct, 2019",
      thumbImage: "images/projects/project_8.0.jpg",
      categories: [filters.SOFTWARE],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
